<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :form_data_seo="form_data_seo"
			:slot_table_list="['operation','notify_status','is_read','sign_status','is_send']" ref="list"
			:rowKey="'id'">
			<template slot="right_btn">
				<export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.tray/exportTray">
				</export-table>
				<!-- <a-button class="float_left" v-auth="'change'" @click="download()" type="primary">导出</a-button> -->
			</template>
			<template slot="left_btn">
				<a-button @click="pu_tray" type="primary">同步托盘</a-button>

			</template>
			<template slot="is_send" slot-scope="data">

				<a-switch default-checked checked-children="是"
					@change="changeSwitch($event,data.record,'self_pickup_auth')" un-checked-children="否"
					v-model="data.record.self_pickup_auth" />

			</template>

			<template slot="is_all" slot-scope="data">
				<a-switch checked-children="承运/自提" un-checked-children="统一配送"
					@change="changeSwitch($event,data.record,'seller_flag')" checked
					v-if="data.record.seller_flag == 1" />
				<a-switch checked-children="承运/自提" style="background-color:#FF9319" un-checked-children="统一配送"
					@change="changeSwitch($event,data.record,'seller_flag')" :checked="false" v-else />
			</template>

			<template slot="operation" slot-scope="data">
				<a @click="go_detail(data.record)">查看</a>
				<!-- <a-divider type="vertical" />
			    <a @click="go_product(data.record)">承运商绑定</a> -->
			</template>
		</TableList>

		<a-modal title="提示" :visible="visible_dealer" :closable='false' :closeIcon='false' :maskClosable='false'>
			<template slot="footer">
				<a-button @click="handleCancel" v-if='up_dealer'>关闭</a-button>
				<span v-else></span>
			</template>
			<p v-if="up_cancel">同步成功！</p>
			<p v-else>正在从DRP系统中同步经(分)销商托盘，大约需要一分钟，同步成功后，会在此页面告知您！</p>
		</a-modal>
	</div>
</template>
<script>
	// @ is an alias to /src
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import EditPop from "@/components/EditPop";
	import {
		get_seller_list,
		get_seller_select,
		changeSellerBind,
		pushTrayInfo
	} from '@/api/push'
	const columns = [{
			title: "名称",
			dataIndex: "seller_name",
		},
		{
			title: "类型",
			dataIndex: "flag_name",
		},
		{
			title: "编号",
			dataIndex: "seller_id",
		},
		{
			title: "电话",
			dataIndex: "phone",
		},
		{
			title: "所属区域",
			dataIndex: "district",
		},
		{
			title: "是否绑定区域",
			dataIndex: "is_region",
		},
		{
			title: "绑定区域",
			dataIndex: "department_name",
		},
		{
			title: "司机信息",
			dataIndex: "driver_name",
		},
		{
			title: "是否绑定微信",
			dataIndex: "is_bind",
		},
		{
			title: "司机是否自提",
			dataIndex: "is_send",
			scopedSlots: {
				customRender: "is_send"
			}
		},
		{
			title: "经销商类型",
			dataIndex: "seller_flag_name",
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];


	export default {
		name: "Index",
		components: {
			Form,
			TableList,
		},
		data() {
			return {
				visible_dealer: false,
				get_table_list: get_seller_list,
				columns,
				visible: false,
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [

						{
							type: "text",
							name: "keyword",
							title: "关键词",
							placeholder: "请输入关键词",
							options: {}
						},
						{
							type: "select",
							name: "flag",
							list: [],
							title: "类型",
							options: {}
						},
						{
							type: "select",
							name: "is_bind",
							list: [{
									key: 1,
									value: "已绑定"
								},
								{
									key: 0,
									value: '未绑定'
								}
							],
							title: "是否绑定微信",
							options: {}
						},
						{
							type: "select",
							name: "is_region",
							list: [{
									key: 1,
									value: "已绑定"
								},
								{
									key: 0,
									value: '未绑定'
								}
							],
							title: "是否绑定区域",
							options: {}
						},
						{
							type: "select",
							name: "seller_flag",
							list: [{
									key: 2,
									value: '统一配送'
								},
								{
									key: 1,
									value: '承运/自提'
								},
							],
							title: "司机类型",
							options: {}
						},
					],
				},
			};
		},
		created() {
			this.get_select()
		},
		mounted() {
			// window.addEventListener('popstate', this.handleBackButton, false);
		},
		activated() {
				this.$refs.list.get_list();
		},

		methods: {
			handleBackButton() {
				if (this.$refs.list) {
					this.$refs.list.get_list();
				}
			},
			changeSwitch(e, data, type) {
				let value = 1
				if (type == 'self_pickup_auth') {
					value = e ? 1 : 0
				} else if (type == 'seller_flag') {
					value = e ? 1 : 2
				}
				changeSellerBind({
					data: {
						sales_seller_id: data.id,
						type: type,
						value: value,
					},
					info: true
				}).then(res => {
					if (type == 'seller_flag') {
						data.seller_flag = e ? 1 : 2
					}
				})
				console.log(data, type)
			},
			//下拉
			get_select() {
				get_seller_select().then(res => {
					this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'flag', 'list',
						res.data.list)
				})
			},
			//查看司机信息
			go_detail(e) {
				this.$keep_route.add(this.$options.name);
				this.$router.push({
					path: '/seller/detail',
					query: {
						id: e.id
					}
				})
			},
			pu_tray() {
				this.visible_dealer = true
				pushTrayInfo().then(res => {
					this.up_dealer = true;
				}).catch(res => {
					//this.up_dealer= true;
				})
			},
			handleCancel() {
				this.visible_dealer = false
				this.up_dealer = false
			},
		}
	};
</script>